/* stylelint-disable property-no-unknown */

/* @font-face {
  font-family: Inter;
  font-weight: 400 900;
  font-display: block;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("../fonts/Inter-roman.var.woff2") format("woff2");
} */

/* @font-face {
  font-family: Inter;
  font-weight: 400 900;
  font-display: block;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("../fonts/Inter-italic.var.woff2") format("woff2");
} */

/* inter-regular - latin */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/optimized/inter-v12-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/optimized/inter-v12-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-500 - latin */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/optimized/inter-v12-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/optimized/inter-v12-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-600 - latin */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/optimized/inter-v12-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/optimized/inter-v12-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-700 - latin */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/optimized/inter-v12-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/optimized/inter-v12-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-800 - latin */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/optimized/inter-v12-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/optimized/inter-v12-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
