@charset "UTF-8";
@import url("fonts.css");
@import url("jit-refresh.css"); /* triggers frontend rebuilds */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --font-weight: 400;
    --font-weight-multiplier: 1;
    --letter-spacing: 0;
  }

  .dark {
    --font-weight-multiplier: 0.85;
    --letter-spacing: 0.02ch;
  }

  strong,
  b,
  th,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    --font-weight: 700;
  }

  *,
  *::before,
  *::after {
    font-weight: calc(var(--font-weight, 400) * var(--font-weight-multiplier, 1));
    letter-spacing: var(--letter-spacing, 0);
  }

  ::-webkit-scrollbar {
    @apply rounded-sm h-1 w-1.5;
  }

  ::-webkit-scrollbar-thumb {
    @apply rounded-sm bg-radix-slate6 opacity-50;
  }
}
